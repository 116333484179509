// eslint-disable
// Google Calendar AUTH
window.CLIENT_ID = '690867427780-qv1d5ju729251vn0oh46sdctns414ru0.apps.googleusercontent.com';
window.SCOPES = ['https://www.googleapis.com/auth/calendar'];

window.calendarUtils = {

  authResult: undefined, // stored dynamically
  initialLoad: true, // updated by script as it runs, only true on first run.
  authorized: false, // updated by script as it runs, if ever authorizes.
  username: '',

  gapiAuthorizeWithCallback() {
    window.open(`https://mapmycustomers.com/app/calendar/gapi-calendar-auth.php?username=${CAL.username}`, '_blank', 'width=600, height=600');
    window.checkAuthorizationComplete();
  },

  showHideCalendarButtons(authToken, callback) {
    // this method can be called without authResult, in which case it will use the last stored result.
    // this is for in-app navigation needing to reset the buttons accordingly.
    // CAL.authResult = authResult || CAL.authResult;
    // authResult = CAL.authResult;
    const authorizeButton = document.getElementById('authorizeButton');
    const addToCalendarButton = document.getElementById('addToCalendarButton');
    const syncDashBox = document.getElementById('syncDashBox');
    const beginAuthCal = document.getElementById('beginAuthCal');
    const beginReminderSync = document.getElementById('beginReminderSync');

    if (addToCalendarButton) {
      addToCalendarButton.innerHTML = 'Add to Calendar';
    }

    if (authToken) {
      console.warn('Calendar result: authorized');
      CAL.authorized = true;
      if (window.syncCalStatusSave) window.syncCalStatusSave();
      else setTimeout(window.syncCalStatusSave, 4000);

      $('#googleCalendarButton').html('Disconnect');
      $('#googleCalendarButton').off('click');
      $('#googleCalendarButton').removeAttr('onclick');
      $('#googleCalendarButton').attr('onclick', 'window.revokeGoogleToken()');
      $('#googleCalendarSettingsLabel').html('Remove Google Calendar Authorization');
      $('#googleCalendarSettingsRow').show();

      // Hide auth UI, then load client library.
      if (authorizeButton) {
        authorizeButton.style.display = 'none';
        addToCalendarButton.style.display = 'inline';
      }
      if (syncDashBox) {
        syncDashBox.style.display = 'block';
      }
      if (beginAuthCal) {
        beginAuthCal.style.display = 'none';
      }
      if (beginReminderSync) {
        beginReminderSync.style.display = 'inline';
      }
      $('#syncStopsToCalendar').show();

      gapi.client.setToken({
        access_token: authToken,
      });
      gapi.auth.setToken({
        access_token: authToken,
      });

      CAL.loadCalendarApi(callback);
    } else {
      console.warn('Calendar result: not authorized');
      CAL.authorized = false;
      if (window.syncCalStatusSave) window.syncCalStatusSave();
      else setTimeout(window.syncCalStatusSave, 4000);

      // if (!authResult) {
      //   console.warn("Calendar result: none? First run? should we hide stuff? used to return here, trying hiding stuff for now");
      //   // return; // first run. // wait, what?
      // }
      $('#googleCalendarButton').html('Authorize');
      $('#googleCalendarButton').off('click');
      $('#googleCalendarButton').click(window.calendarUtils.gapiAuthorizeWithCallback);
      // $("#googleCalendarSettingsButton").click(window.calendarUtils.gapiAuthorize.bind(null, function(authResult) {
      //   // alert("running with", JSON.stringify(authResult))
      //   // if (authResult && !authResult.error) {
      //   //   CAL.authorized = true;
      //   //   swal("Connected", "You've added Google calendar authorization. Nice work!", "success")
      //   //     .then(function() {
      //   //       window.calendarUtils.showHideCalendarButtons(authResult);
      //   //     });
      //   // }
      //   // else {
      //   //   swal("Uh-Oh", "For some reason, we weren't able to link to your Google account. Try contacting customer support at support(at)mapmycustomers.com.", "error");
      //   // }
      // }));
      $('#googleCalendarSettingsLabel').html('Enable Google Calendar Authorization');
      $('#googleCalendarSettingsRow').show();

      // Show auth UI, allowing the user to initiate authorization by
      // clicking authorize button.
      // let's go a different route: now it just always checks for authorization when it runs. see syncAllReminders()
      // $('#syncStopsToCalendar').click(window.calendarUtils.gapiAuthorize);

      if (authorizeButton) {
        // authorizeButton.style.display = 'inline';
        $('#authorizeButton').show();
        addToCalendarButton.style.display = 'none';
      }
      if (syncDashBox) {
        syncDashBox.style.display = 'block';
      }
      if (beginAuthCal) {
        beginAuthCal.style.display = 'inline';
      }
      if (beginReminderSync) {
        beginReminderSync.style.display = 'none';
      }
    }
  },

  /* loading client library */
  loadCalendarApi(callback) {
    gapi.client.load('calendar', 'v3')
      .then(() => {
        if (callback) callback();
      })
      .catch((error) => {
        console.error(error);
      });
  },

};

window.CAL = window.calendarUtils;
